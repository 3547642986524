import React, { createContext, useContext, useEffect, useState, useRef, useMemo } from 'react';
import Pusher from 'pusher-js';

// Create the context
const PusherContext = createContext();

// Create a provider component
export const PusherProvider = ({ token, children }) => {
  const [onlineMembers, setOnlineMembers] = useState([]);
  const activePresenceRef = useRef(null);

  useEffect(() => {
    if (!token) return; // Exit early if token is not provided

    const pusher = new Pusher('f36706c4316bca528c78', {
      cluster: 'ap2',
      authEndpoint: 'https://soulfulpsychicsapi.dotserviz.co/chatify/api/chat/auth',
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    const activeStatusChannel = pusher.subscribe('presence-activeStatus');
    activePresenceRef.current = activeStatusChannel;

    activeStatusChannel.bind('pusher:subscription_succeeded', (e) => {
      const result = Object.entries(e.members).map(([id, { name }]) => ({
        id: Number(id),
        name,
      }));
      setOnlineMembers(result);
    });

    activeStatusChannel.bind('pusher:member_added', (member) => {
      const newMember = { id: member?.id, name: member?.info?.name };
      setOnlineMembers((prev) => [...prev, newMember]);
    });

    // eslint-disable-next-line
    return () => {
      activeStatusChannel.unbind_all();
      activeStatusChannel.unsubscribe();
      pusher.disconnect();
    };
  }, [token]);

  // Leaved
  activePresenceRef?.current?.bind('pusher:member_removed', (member) => {
    console.log(member, 'pusher:member_removed');

    // eslint-disable-next-line;
    const new_arr = onlineMembers.filter((item) => {
      return item?.id !== Number(member?.id);
    });
    console.log(new_arr, 'UPDATED ONLINE ARRAY');
    setOnlineMembers(new_arr);
  });
  // Wrap context value in useMemo
  const contextValue = useMemo(() => ({ onlineMembers }), [onlineMembers]);

  return <PusherContext.Provider value={contextValue}>{children}</PusherContext.Provider>;
};

// Custom hook to access online members
export const useOnlineMembers = () => {
  return useContext(PusherContext);
};
