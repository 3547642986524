export const mainurl = process.env.REACT_APP_MAINURL;
export const chatUrl = process.env.REACT_APP_CHATURL;

export const api = {
  category: `${mainurl}v1/get_categorized_consultants`,
  getConsultant: `${mainurl}v1/consultant/get_consultant_by_id`,
  getConsultants: `${mainurl}v1/consultant/get_consultants`,
  getConsultantations: `${mainurl}v1/consultant/get_consultations`,
  get_categories: `${mainurl}v1/get_categories`,
  get_reviews: `${mainurl}v1/consultant/get_consultant_reviews`,
  signIn: `${mainurl}v1/users/login`,
  signUp: `${mainurl}v1/users/sign_up`,
  verifyOtp: `${mainurl}v1/users/verify_otp`,
  updateProfile: `${mainurl}v1/users/update_profile`,
  updateAvatar: `${mainurl}v1/users/update_profile_picture`,
  verifyForgotOtp: `${mainurl}v1/users/verify_forgot_password`,
  change_forgot_password: `${mainurl}v1/users/change_forgot_password`,
  change_password: `${mainurl}v1/users/change_password`,
  change_email_otp: `${mainurl}v1/users/change_email_otp`,
  change_email: `${mainurl}v1/users/change_email`,
  resendOtp: `${mainurl}v1/users/resend_otp`,
  addToFav: `${mainurl}v1/users/add_to_favourite`,
  addToNotify: `${mainurl}v1/users/add_to_notify`,
  checkFav: `${mainurl}v1/users/get_is_favourite`,
  fotgotPassword: `${mainurl}v1/users/forgot_password`,
  get_category_with_consultants: `${mainurl}v1/get_category_with_consultants`,
  get_consultant_for_about_page: `${mainurl}v1/get_consultant_for_about_page`,
  add_consultant: `${mainurl}v1/consultant/create_consultant`,
  delete_consultant: `${mainurl}v1/consultant/delete_consultant`,
  block_unblock: `${mainurl}v1/consultant/block_unblock_consultant`,
  order: `${mainurl}v1/payment/orders`,
  capture: `${mainurl}v1/payment/orders/capture`,
  getChatHistory: `${mainurl}v1/users/get_chat_history`,
  getUsers: `${mainurl}v1/users/get_users`,
  block_unblock_user: `${mainurl}v1/users/block_unblock_user`,
  delete_member: `${mainurl}v1/users/delete_user`,
  get_mails: `${mainurl}v1/mail/get_mails`,
  get_mail_by_id: `${mainurl}v1/mail/get_mail_by_id`,
  email_validate: `${mainurl}v1/users/email_validator`,
  send_email: `${mainurl}v1/mail/send`,
  delete_mail: `${mainurl}v1/mail/delete`,
  add_review: `${mainurl}v1/consultant/save_review_rating`,
};
